import { groq } from "next-sanity";
import { ImageType, imageQuery } from "./image";

export type ProjectLinksType = {
	title: string;
	slug: string;
	category: {
		slug: string;
	};
};

export const projectsLinksQuery = groq`
*[
	_type == "project" && !(_id in path("drafts.**"))
] {
  ...
  title, 
  "slug": slug.current,
  category->{
    "slug": slug.current
  }
}`;

export type ProjectType = {
	id: string;
	title: string;
	vimeo: string;
	href: string;
	slug: string;
	category: {
		slug: string;
		id: string;
	};
	thumbnail: ImageType;
	images: ImageType[];
	hasPressRelease: boolean;
	pressReleaseLink: string;
	pressRelease: {
		name: string;
		extension: string;
		url: string;
		id: string;
	};
	hasAnalysis: boolean;
	analysis: string;
};

export const projectQuery = groq`
  "id": _id,
  title,
  vimeo,
  href,
  "slug": slug.current,
  category->{
	"slug": slug.current,
	"id": _id,
  },
  "thumbnail": thumbnail->${imageQuery},
  images[]-> ${imageQuery},
  hasPressRelease,
  pressReleaseLink,
  pressRelease {
    ...asset->{
      "name": originalFilename,
      extension,
      url,
      "id": _id,
    }
  },
  hasAnalysis,
  analysis,
`;

export const projectBySlug = groq`
*[
	_type == "project" && slug.current == $slug && !(_id in path("drafts.**"))
][0] {
  ${projectQuery}
}`;
