"use client";
import { cn } from "@/lib/utils";

import { Button } from "@/components/ui/button";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { HTMLAttributes, useRef } from "react";

import { sanity } from "@/sanity/lib/client";
import {
	categoriesLinksQuery,
	CategoryLinksType,
} from "@/sanity/query/getCategories";
import {
	getSettingQuery,
	SettingsResponseType,
} from "@/sanity/query/getSettings";
import { useQueries } from "@tanstack/react-query";

import dynamic from "next/dynamic";
const FadeIn = dynamic(() => import("@/components/animations/FadeIn"));
const CloseButton = dynamic(() => import("@/components/CloseButton"));
const Desktop = dynamic(() =>
	import("@/components/Responsive").then((mod) => mod.Desktop),
);
const Mobile = dynamic(() =>
	import("@/components/Responsive").then((mod) => mod.Mobile),
);

const Navbar = ({ className }: HTMLAttributes<HTMLDivElement>) => {
	const path = usePathname();

	const {
		data: { settings, categories },
		isPending,
		isError,
	} = useQueries({
		queries: [
			{
				queryKey: ["categories"],
				queryFn: async (): Promise<CategoryLinksType[]> => {
					return await sanity.fetch(categoriesLinksQuery);
				},
			},
			{
				queryKey: ["settings"],
				queryFn: async (): Promise<SettingsResponseType> => {
					return await sanity.fetch(getSettingQuery);
				},
			},
		],
		combine: (results) => ({
			data: {
				categories: results[0],
				settings: results[1],
			},
			isPending: results.some((result) => result.isPending),
			isError: results.some((result) => result.isError),
		}),
	});

	if (isPending || isError) return null;

	// Find the active page
	const active = {
		homepage: path === "/",
		about: path.includes("/synopsis"),
		category: categories?.data?.find((category) =>
			path.includes(category.slug),
		)?.slug,
		project: path.includes("/project"),
	};

	return (
		<nav className="">
			<Mobile>
				<FadeIn
					className={cn(
						"fixed left-0 right-0 top-[13px] z-40 grid grid-cols-3 overflow-hidden bg-inherit pl-[8px]",
						className,
					)}
					duration={0.3}
					delay={0}
				>
					<ActiveNavElement isActive={active.homepage}>
						<Link href="/" className="col-span-1 mr-auto">
							{settings.data?.siteName}
						</Link>
					</ActiveNavElement>

					<div className="scrollbar-hide col-span-2 w-full overflow-y-hidden overflow-x-scroll">
						<div className="mx-auto flex w-fit space-x-[8px] pr-[8px]">
							{categories?.data?.map((category, i) => {
								return (
									<ActiveNavElement
										key={i}
										isActive={
											active.category === category.slug
										}
									>
										<Link href={`/format/${category.slug}`}>
											{category.title}
										</Link>
									</ActiveNavElement>
								);
							})}

							<ActiveNavElement isActive={active.about}>
								<Link href="/synopsis">Synopsis</Link>
							</ActiveNavElement>
						</div>
					</div>
				</FadeIn>
			</Mobile>
			<Desktop>
				{!isPending && (
					<FadeIn
						// ref={navRef}
						// initial={{ opacity: 0 }}
						// animate={{ opacity: 1 }}
						// transition={{ duration: 1, ease: "easeInOut" }}
						// role="navigation"
						tabIndex={-1}
						className={cn(
							"scrollbar-hide fixed left-0 right-0 top-0 z-50 flex h-16 min-h-16 w-full items-center justify-between overflow-y-hidden overflow-x-scroll bg-inherit px-[13px] font-abcOracle focus:outline-none",
							className,
						)}
						duration={0.5}
						delay={0}
					>
						<ActiveNavElement isActive={active.homepage}>
							<Link href="/">{settings.data?.siteName}</Link>
						</ActiveNavElement>

						<div className="scrollbar-hide flex w-full max-w-[66vw] justify-center gap-[13px] overflow-y-hidden overflow-x-scroll p-1">
							{categories?.data?.map((category, i) => {
								return (
									<ActiveNavElement
										key={i}
										isActive={
											active.category !== undefined &&
											active.category === category.slug
										}
									>
										<Link href={`/format/${category.slug}`}>
											{category.title}
										</Link>
									</ActiveNavElement>
								);
							})}
						</div>

						<div className="flex w-fit min-w-32 items-center justify-end capitalize">
							<ActiveNavElement isActive={active.about}>
								<Link href="/synopsis">Synopsis</Link>
							</ActiveNavElement>
						</div>
					</FadeIn>
				)}
			</Desktop>
		</nav>
	);
};

export default Navbar;

function ActiveNavElement({
	isActive,
	children,
	...props
}: {
	isActive: boolean;
	children: React.ReactNode;
	props?: HTMLAttributes<HTMLButtonElement>;
}) {
	const ref = useRef<HTMLButtonElement>(null);

	if (isActive) {
		ref.current?.scrollIntoView({
			behavior: "smooth",
			block: "center",
			inline: "center",
		});
	}

	return (
		<Button
			ref={ref}
			variant="ghost"
			size="sm"
			className={cn(
				"h-fit py-1 text-center capitalize",
				isActive ? "text-primary-mediumgrey" : "",
			)}
			asChild
			{...props}
		>
			{children}
		</Button>
	);
}
