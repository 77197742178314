import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}
export function isIOS() {
	var iosQuirkPresent = function () {
		var audio = new Audio();

		audio.volume = 0.5;
		return audio.volume === 1; // volume cannot be changed from "1" on iOS 12 and below
	};

	if (typeof navigator === "undefined") return false;

	var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
	var isAppleDevice = navigator.userAgent.includes("Macintosh");
	var isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)

	return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
}

export function mod(a: number, b: number) {
	const r = a % b;
	return r < 0 ? r + b : r;
}

export const waitFor = (ms: number) =>
	new Promise((res) => setTimeout(res, ms));
