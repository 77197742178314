import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-abc-oracle\",\"src\":[{\"path\":\"./fonts/light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./fonts/book.woff2\",\"weight\":\"350\",\"style\":\"normal\"},{\"path\":\"./fonts/reg.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./fonts/bold.woff2\",\"style\":\"normal\",\"weight\":\"bold\"}],\"display\":\"swap\"}],\"variableName\":\"ABC_Oracle\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
