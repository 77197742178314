const assetMedia = `{
    url,
    "dimensions": metadata.dimensions,
    "lqip":metadata.lqip
}`;

export const getSettingQuery = `*[_type == "settings" && !(_id in path("drafts.**"))][0]{
    siteName,
    siteUrl,
    siteCredit,
	siteCreditURL,
    siteDescription,
    favIcon{
        asset->${assetMedia}
    },
    socialIcon{
		asset->${assetMedia}
	}
}`;


export type SettingsResponseType = {
	siteName: string;
	siteUrl: string;
	siteCredit: string;
	siteCreditURL: string;
	siteDescription: string;
	socialIcon: {
		asset: {
			url: string;
			lqip: string;
			dimensions: {
				height: number;
				width: number;
			};
		};
	};
	favIcon: {
		asset: {
			url: string;
			lqip: string;
			dimensions: {
				height: number;
				width: number;
			};
		};
	};
};
