import { groq } from "next-sanity";

export type ImageType = {
	project: {
		slug: string;
		id: string;
	};
	category: {
		slug: string;
		id: string;
	};
	id: string;
	showGrid: boolean;
	orderRank: string;
	alt: string;
	url: string;
	metadata: {
		lqip: string;
		dimensions: {
			aspectRatio: number;
			height: number;
			width: number;
		};
	};
};
export const imageQuery = groq`{
  "project": project-> {
    "slug": slug.current,
	title,
    "id": _id
  },
  "category": project->category->{
    "slug": slug.current,
	title,
    "id": _id
  },
  "id": _id,
  showGrid,
  orderRank,
  alt,
  ...img.asset->{
    url,
        metadata {
          lqip,
          dimensions {
            aspectRatio,
            height,
            width
          }
      	}
  }
}`;
