import { groq } from "next-sanity";
import { projectQuery, ProjectType } from "./project";

export type CategoryLinksType = {
	orderRank: string;
	title: string;
	slug: string;
	id: string;
};

export const categoriesLinksQuery = groq`
*[_type =="category" && !(_id in path("drafts.**"))] {
	orderRank,
	title,
	"slug": slug.current, 
	_id,
}|order(orderRank)
`;

export type CategoryBySlugType = {
	slug: string;
	title: string;
	id: string;
	projects: ProjectType[];
};
export const categoryBySlug = groq`
*[
	_type == "category" && slug.current == $slug && !(_id in path("drafts.**"))
] [0]{
  "slug": slug.current,
  title,
  "id": _id,
  projects[]->{
	${projectQuery}
  }
}
`;
